
import style from './mvFooter.module.scss'
import React from "react";
import { LayoutDataFooter } from '../../store/Contentdata'
import ScreenSize from "../../utils/screen"
const WINDWOSSIZE = ScreenSize();
const MvFooter = () => {
    return (
        <div className={style.content}>
            <div className={style.Footer}>
                {LayoutDataFooter.company && (
                    <div className={style.Footer_main}>
                        <p>{LayoutDataFooter.company.titleOne}</p>
                        <p>{LayoutDataFooter.company.titleTwo}</p>
                        <p>{LayoutDataFooter.company.titleTherr}</p>
                        <p>{LayoutDataFooter.company.titleFout}</p>
                    </div>
                )}

                {LayoutDataFooter.contact && (
                    <div className={style.Footer_main}>
                        <p>{LayoutDataFooter.contact.titleOne}</p>
                        <p>{LayoutDataFooter.contact.titleTwo}</p>
                        <p>{LayoutDataFooter.contact.titleTherr}</p>
                        <p>{LayoutDataFooter.contact.titleFout}</p>
                    </div>
                )}

            </div>
        </div>

    )
}

export default MvFooter