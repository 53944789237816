
/*
*  PC / MV 检测函数
*/

const ScreenSize = () => {
  const isScreenLarge = window.innerWidth > 800;
  return isScreenLarge;
}
export default ScreenSize;
