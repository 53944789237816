// App.tsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Loading from './assets/icon/asshtml/Loding';
import HeaderTabbat from "./components/Tabbat/Tabbat";

import Footer from "./components/Footer/Footer"

import ScreenSize from './utils/screen';
import MvTabbat from './components/MvTabbat/MvTabbat';
import MvFooter from './components/MvFooter/MvFooter';

import Tackiness from './components/Tackiness/Tackiness';
import MvTackiness from './components/MvTackiness/MvTackiness';


const WINDWOSSIZE = ScreenSize()
const LazyRoutes = lazy(() => import('./routers/index'));

function App() {

  console.log(WINDWOSSIZE);

  return (
    <>
      <Router>
        {WINDWOSSIZE ? (<HeaderTabbat />) : (<MvTabbat />)}
        {/* {WINDWOSSIZE ? (<Tackiness />) : (<></>)} */}
        {/* {WINDWOSSIZE ? (<Tackiness />) :( <MvTackiness />)} */}
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="*" element={<LazyRoutes />} />
          </Routes>
        </Suspense>
        {WINDWOSSIZE ? <Footer /> : <MvFooter />}
      </Router>
    </>
  );
}

export default App;
