import style from './mvTabbat.module.scss';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ScreenSize from '../../utils/screen';
import { LayoutDataHeader } from '../../store/Contentdata';
import { LayoutDataHeaderType } from '../../utils/type';

const imgdata ='/assets/icon/黑色.png'


const MvTabbat = () => {
    const navigate = useNavigate();
    const [activeOption, setActiveOption] = useState<string>('');
    const [currentBackground, setCurrentBackground] = useState<string>('');
    const [BannerTeital, setBannerTeital] = useState<LayoutDataHeaderType | undefined>();

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;


        const selectedOption = LayoutDataHeader.find((option) => option.path === selectedValue);
        console.log(selectedOption);

        if (selectedOption) {
            navigate(selectedOption.path);
            setActiveOption(selectedOption.path);
            setCurrentBackground(selectedOption.background);
            setBannerTeital(selectedOption);
        }
    };

    useEffect(() => {
        handleSelectChange({ target: { value: LayoutDataHeader[2].path } } as React.ChangeEvent<HTMLSelectElement>);
    }, []);

    return (
        <div className={style.content} style={{ background: `url(${currentBackground})` }}>
            <div className={style.header}>
                <div className={style.imgdata}>
                    <img src={imgdata} alt="" />
                </div>
                <select
                    className={style.navigate}
                    value={activeOption}
                    onChange={handleSelectChange}
                    style={{ border: 'none' }}
                >
                    {LayoutDataHeader.map((option) => (
                        <option
                            className={style.activediv}
                            key={option.id}
                            value={option.path}
                        >
                            {option.label}
                        </option>
                    ))}

                </select>
            </div>
            <div className={style.banner}>
                <div className={style.banner_teitaEN}>{BannerTeital && BannerTeital.banner_teitaEN}</div>
                <div className={style.banner_teita}>{BannerTeital && BannerTeital.banner_teita}</div>
                <div className={style.banner_teitadata}>{BannerTeital && BannerTeital.banner_teitadata}</div>
            </div>
        </div>
    );
};

export default MvTabbat;
