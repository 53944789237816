import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from './tabbat.module.scss';
import { LayoutDataHeader } from '../../store/Contentdata'
import { LayoutDataHeaderType } from "../../utils/type";

const imgdata = '/assets/icon/黑色.png'


const HeaderTabbat = () => {

    const navigate = useNavigate();

    const [activeOption, setActiveOption] = useState<string>('');

    const [animate, setanimate] = useState<boolean>();

    const [currentBackground, setCurrentBackground] = useState<string>('');

    const [BannerTeital, setBannerTeital] = useState<LayoutDataHeaderType | undefined>();


    const handleOptionClick = (path: string, background: string, option: LayoutDataHeaderType) => {
        setanimate(!animate);
        navigate(path);
        setActiveOption(path);
        setCurrentBackground(background);
        setBannerTeital(option);
    }

    useEffect(() => {
        const indexToRemove = LayoutDataHeader.findIndex(item => item.id === 3);
        if (indexToRemove !== -1) {
            LayoutDataHeader.splice(indexToRemove, 1);
        }
        handleOptionClick(LayoutDataHeader[0].path, LayoutDataHeader[0].background, LayoutDataHeader[0]);
        setanimate(true)
    }, []);
    return (
        < div className={style.content} style={{ background: `url(${currentBackground})` }}>
            <div className={style.header}>
                <div className={style.imgdata}>
                    <img src={imgdata} alt="" />
                </div>
                <div className={style.navigate}>
                    {LayoutDataHeader.map((option) => (
                        <div
                            key={option.id}
                            className={`${activeOption === option.path ? style.active : style.activediv}`}
                            onClick={() => handleOptionClick(option.path, option.background, option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
            <div className={style.banner}>
                <div className={`${style.banner_teitaEN} animate__animated   ${animate ? '  animate__backInLeft' : "animate__backOutRight"} `}>{BannerTeital && BannerTeital.banner_teitaEN}</div>
                <div className={`${style.banner_teita}  animate__animated   ${animate ? '  animate__backInRight' : "animate__fadeInTopRight"} `}>{BannerTeital && BannerTeital.banner_teita}</div>
                <div className={`${style.banner_teitadata}  animate__animated   ${animate ? '  animate__bounceInUp' : "animate__backInRight"} `}>{BannerTeital && BannerTeital.banner_teitadata}</div>
            </div>
        </div >
    );
};

export default HeaderTabbat;
