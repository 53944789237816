import React from "react";
import style from './footer.module.scss'
import { LayoutDataFooter } from '../../store/Contentdata'
import { LayoutFDteital } from "../../store/Layoutdata";

import ScreenSize from "../../utils/screen"
const WINDWOSSIZE = ScreenSize();

const Footer = () => {
    return (
        <div className={style.content}>
            {/* <div className={style.FooterMain_div_teital}>
                {LayoutFDteital.map(option => (
                    <span>
                        <span> {option} </span>
                        <span>|</span>
                    </span>
                ))}
            </div> */}
            <div className={style.Footer}>
                {LayoutDataFooter.company && (
                    <div className={style.Footer_main}>
                        <p>{LayoutDataFooter.company.titleOne}</p>
                        <p>{LayoutDataFooter.company.titleTwo}</p>
                        <p>{LayoutDataFooter.company.titleTherr}</p>
                        <p>{LayoutDataFooter.company.titleFout}</p>
                    </div>
                )}

                {LayoutDataFooter.contact && (
                    <div className={style.Footer_main}>
                        <p>{LayoutDataFooter.contact.titleOne}</p>
                        <p>{LayoutDataFooter.contact.titleTwo}</p>
                        <p>{LayoutDataFooter.contact.titleTherr}</p>
                        <p>{LayoutDataFooter.contact.titleFout}</p>
                    </div>
                )}

                {LayoutDataFooter.contact && (
                    <div className={style.Footer_main}>
                        <p> <img src={LayoutDataFooter.QRcode.icon} alt="" /></p>
                        <p style={{textAlign:"center"}}>{LayoutDataFooter.QRcode.title}</p>

                    </div>
                )}

             
            </div>

        </div>

    )
}

export default Footer
