
/*
*  路由加载页面
*/

const Loading = () => {
    return (
        <>
            Loading...
        </>
    )
}

export default Loading