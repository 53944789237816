/*
*  公共-头部导航栏信息
*/

import {
    LayoutDataHeaderType,
    LayoutDataFooterType
} from "../utils/type"

export const LayoutDataHeader: LayoutDataHeaderType[] = [
    {
        id: 1,
        label: "首页",
        path: '/',
        background: '/assets/首页/banner.png',
        banner_teitaEN: 'HUATENGHANGTIAN',
        banner_teita: '华腾航天，让数字化更简单',
        banner_teitadata: 'Huateng Aerospace, Making Digitization Easier',
    },
    {
        id: 2,
        label: "关于我们",
        path: '/Aboutus',
        background: '/assets/关于我们/banner.png',
        banner_teitaEN: '',
        banner_teita: '关于我们',
        banner_teitadata: 'ABOUT US',


    },
    {
        id: 3,
        label: "业务中心",
        path: '/Cases',
        background: '/assets/业务中心/banner.png',
        banner_teitaEN: '',
        banner_teita: '业务中心',
        banner_teitadata: 'SERVICE CENTER',


    },
    {
        id: 4,
        label: "服务案例",
        path: '/Service',
        background: '/assets/服务案例/banner.png',
        banner_teitaEN: '',
        banner_teita: '服务案例',
        banner_teitadata: 'SERVICE CASES',


    },
    {
        id: 5,
        label: "联系我们",
        path: '/ContactUs',
        background: '/assets/联系我们/banner.png',
        banner_teitaEN: '',
        banner_teita: '联系我们',
        banner_teitadata: 'CONTACT US',
    }
];


/*
*  公共-页脚栏信息
*/

export const LayoutDataFooter: LayoutDataFooterType = {
    company: {
        titleOne: '陕西华腾航天数字技术有限公司',
        titleTwo: '营业执照：91611101MA7BT3508Y',
        titleTherr: 'ICP备案号码：陕ICP备2022006934号',
        titleFout: '地址：陕西省西安市雁塔区',
    },
    contact: {
        titleOne: '联系我们',
        titleTwo: '电话：18092574315',
        titleTherr: '邮箱：18092574315@189.cn',
        titleFout: 'QQ ： 249771113',
    },
    QRcode: {
        icon: '/assets/联系我们/华腾航天.png',
        title: '微信号',
    }
}
